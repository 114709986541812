import 'owl.carousel';
import helper from './helpers';
const fancybox = require('@fancyapps/fancybox');

jQuery(document).ready(function($) {
	// on scroll
	$(window).on('scroll', function() {
	// if scroll meets end of page, stop scrolling
	if($(window).scrollTop() + $(window).height() != $(document).height()) {
	}
	});
	// on resize
	$(window).on('resize', function() {
	});
	// fancybox
	$('[data-fancybox]').fancybox();
});