import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import '../sass/sass.scss';
import './functions.js';

import Vue from 'vue';

//Vue.prototype.$app = app;
Vue.config.productionTip = false;

new Vue({
	el: '#app',
});